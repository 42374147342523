import React from 'react';
import { PathService } from '../../services';
import { VideosViewContext, TemplateProps } from '../../types';
import { OurInsightsVideosView } from '../../views';

const OurInsightsVideos: React.FunctionComponent<
  TemplateProps<VideosViewContext>
> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return <OurInsightsVideosView videos={JSON.parse(pageContext.videos)} />;
};

export default OurInsightsVideos;
